<template>
  <div class="q-pa-sm">
    <task-queues
      :options="options"
      @select="onRowDblClick"
    />
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import TaskQueues from '../../components/queue/TaskQueues.vue'

export default {
  name: 'ReturnQueues',
  components: {
    TaskQueues
  },
  computed: {
    options () {
      return {
        settings: true,
        type: 'returning',
        title: 'Return Queues',
        service: this.$service.assemblingQueue,
        adapters: ['returning']
      }
    }
  },
  methods: {
    ...mapMutations([
      'setAssemblingQueue'
    ]),
    onRowDblClick ({ item, toFilters }) {
      this.setAssemblingQueue(item)
      const path = `/workstation/queues/packing/entity/${item.id}${toFilters ? '?start=true' : ''}`
      this.$router.push(path)
    }
  }
}
</script>
